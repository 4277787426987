import React, { useState } from "react"

import toast from "react-hot-toast"
import { validation } from "../../../service/validators"

import API from "../../../api"

const NewsLetter = props => {
  
  const [emailId, setEmailId] = useState("")
  const [validationErrors, setValidationErrors] = useState({})

  const onEmailChange = event => {
    setEmailId(event.target.value)
  }

  const onSubmit = () => {
    const obj = {
      emailId: emailId,
    }
    const errors = validation(obj)
    if (errors && Object.keys(errors).length > 0) {
      setValidationErrors(errors)
    } else {
      setValidationErrors({})
      API.subscribeNewsLetter(obj)
        .then(response => {
          setEmailId("")
          setValidationErrors({})
          toast.success("Successfully subscribed to the newsletter")
        })
        .catch(err => {
          console.log(err)
          toast.error("Something went wrong")
        })
    }
  }
  return(<></>)
  return (
    <section className="newsletter">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <div className="text_content">
              <h2>Free E-Newsletter</h2>
              <p>
                Get our free e-newsletter to stay up to date on the latest
                health information from experts at Hinduja Hospital.
              </p>
            </div>
          </div>
          <div className="col-md-5">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="xyz@gmail.com"
                aria-label="Recipient's username"
                value={emailId}
                onChange={onEmailChange}
              />
              <div className="input-group-append">
                <button
                  onClick={onSubmit}
                  className="btn"
                  type="button"
                  id="subscribe"
                >
                  Subscribe
                </button>
              </div>
            </div>
            <p className={validationErrors.emailId && "field_error"}>
              {validationErrors.emailId}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewsLetter
